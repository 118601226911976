import { Radio, Stack, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

import { useSwipeableModal } from '@woovi/hooks';
import { getProviderName } from '@woovi/shared';
import { ActionButton } from '@woovi/ui';

import type { UserMenuAccountItem_account$key } from './__generated__/UserMenuAccountItem_account.graphql';
import type { UserMenuAccountItem_user$key } from './__generated__/UserMenuAccountItem_user.graphql';
import UserMenuAccountItemBalance from './UserMenuAccountItemBalance';
import { UserMenuConfirmDefaultAccountModal } from './UserMenuConfirmDefaultAccountModal';
import FeatureTemp from '../../common/v2/featureFlag/FeatureTemp';

type UserMenuAccountItemProps = {
  selectedValue: string;
  account: UserMenuAccountItem_account$key;
  user: UserMenuAccountItem_user$key;
};

const UserMenuAccountItem = ({
  selectedValue,
  ...props
}: UserMenuAccountItemProps): ReactNode => {
  const { open, handleClose, handleOpen } = useSwipeableModal();

  const account = useFragment<UserMenuAccountItem_account$key>(
    graphql`
      fragment UserMenuAccountItem_account on CompanyBankAccount {
        id
        name
        type
        code
        ...UserMenuConfirmDefaultAccountModal_account
      }
    `,
    props.account,
  );

  const user = useFragment<UserMenuAccountItem_user$key>(
    graphql`
      fragment UserMenuAccountItem_user on User {
        ...FeatureTemp_user
      }
    `,
    props.user,
  );

  return (
    <>
      <ActionButton
        variant='text'
        color='secondary'
        onClick={handleOpen}
        sx={{ justifyContent: 'flex-start', px: 0 }}
      >
        <Radio checked={selectedValue === account.id} />
        <Stack sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant='body2' fontWeight={600}>
            {account.name}
          </Typography>
          <UserMenuAccountItemBalance accountId={account.id} />
          <FeatureTemp user={user}>
            <span>
              {account.type} - {account.code} - {getProviderName(account.code)}
            </span>
          </FeatureTemp>
        </Stack>
      </ActionButton>
      <UserMenuConfirmDefaultAccountModal
        open={open}
        onClose={handleClose}
        account={account}
      />
    </>
  );
};

export default UserMenuAccountItem;
