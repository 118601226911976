import { Popover, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { graphql, useFragment } from 'react-relay';

import { ADMIN_ROLE } from '@woovi/roles';

import type { UserMenu_me$key } from './__generated__/UserMenu_me.graphql';
import UserMenuAccounts from './UserMenuAccounts';
import { UserMenuActions } from './UserMenuActions';
import { UserMenuAuths } from './UserMenuAuths';
import { UserMenuCompanyInfo } from './UserMenuCompanyInfo';
import { UserMenuProfile } from './UserMenuProfile';
import FeatureFlag from '../../common/v2/featureFlag/FeatureFlag';

import type { ReactNode } from 'react';

type UserMenuProps = {
  open: boolean;
  onClose: () => void;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  me: UserMenu_me$key;
};

export const UserMenu = ({
  open,
  onClose,
  anchorEl,
  ...props
}: UserMenuProps): ReactNode => {
  const me = useFragment<UserMenu_me$key>(
    graphql`
      fragment UserMenu_me on User {
        ...UserMenuAuths_user
        ...UserMenuProfile_user
        ...UserMenuCompanyInfo_user
        ...UserMenuAccounts_user
        ...FeatureFlag_user
      }
    `,
    props.me,
  );

  if (!me) {
    return null;
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Stack>
        <Stack p={2} gap={1}>
          <UserMenuProfile user={me} />
          <UserMenuActions onClose={onClose} />
          <UserMenuCompanyInfo user={me} />
        </Stack>
        <FeatureFlag user={me} roles={[ADMIN_ROLE]}>
          <Divider />
          <UserMenuAccounts user={me} />
        </FeatureFlag>
        <Divider />
        <Stack>
          <UserMenuAuths user={me} />
        </Stack>
      </Stack>
    </Popover>
  );
};
