import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

import { APP_PATH } from './appPath';

export const transactionRoutes: RouteType[] = [
  {
    name: 'transactions',
    path: `${APP_PATH}/transactions`,
    labelHeader: ({ t }) => t('Transactions'),
    label: ({ t }) => t('Transactions'),
    component: loadable(
      () => import('../../../components/pixTransaction/OpenpixPixTransaction'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.TRANSACTIONS,
      ROLES.OPEN_PIX.REFUND,
      ROLES.OPEN_PIX.REPORT,
      ROLES.OPEN_PIX.DISPUTE_ALL,
      ROLES.OPEN_PIX.DISPUTE_VIEW,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'transaction.report',
        path: `${APP_PATH}/transactions/report`,
        labelHeader: ({ t }) => t('Report'),
        label: ({ t }) => t('Report'),
        component: loadable(
          () =>
            import('../../../components/pixTransaction/PixTransactionReport'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.REFUND,
          ROLES.OPEN_PIX.REPORT,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'transaction.statement',
        path: `${APP_PATH}/transactions/statement`,
        labelHeader: ({ t }) => t('Statement'),
        label: ({ t }) => t('Statement'),
        component: loadable(
          () =>
            import('../../../components/pixTransaction/TransactionStatement'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'transaction.list',
        path: `${APP_PATH}/transactions/list`,
        labelHeader: ({ t }) => t('Transactions'),
        label: ({ t }) => t('Transactions'),
        component: loadable(
          () => import('../../../components/pixTransaction/TransactionList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.REFUND,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'dispute.list',
        path: `${APP_PATH}/transactions/disputes`,
        labelHeader: ({ t }) => t('Disputes'),
        label: ({ t }) => t('Disputes/MED'),
        component: loadable(
          () => import('../../../components/dispute/DisputeList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.DISPUTE.VIEW,
          ROLES.DISPUTE.ALL,
          ROLES.OPEN_PIX.DISPUTE_ALL,
          ROLES.OPEN_PIX.DISPUTE_VIEW,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },

  {
    name: 'transaction.details',
    path: `${APP_PATH}/transaction/details/:id`,
    labelHeader: ({ t }) => t('Transactions'),
    label: ({ t }) => t('Transactions'),
    component: loadable(
      () =>
        import(
          '../../../components/pixTransaction/details/PixTransactionDetails'
        ),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.TRANSACTIONS,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'transaction.details.data',
        path: `${APP_PATH}/transaction/details/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () =>
            import(
              '../../../components/pixTransaction/details/PixTransactionData'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
];
