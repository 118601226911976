import { graphql } from 'react-relay';

export const AppEventReadAll = graphql`
  mutation AppEventReadAllMutation($input: AppEventReadAllInput!) {
    AppEventReadAll(input: $input) {
      me {
        appEvents(first: 10)
          @connection(key: "Notification_me_appEvents", filters: []) {
          edges {
            node {
              id
            }
          }
        }
      }
      error
      success
    }
  }
`;
