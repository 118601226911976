import loadable from '@loadable/component';

import { ADMIN_ROLE, ROLES, MODULES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

import { APP_PATH } from './appPath';

export const disputeRoutes: RouteType[] = [
  {
    name: 'dispute.detail',
    path: `${APP_PATH}/dispute/details/:id`,
    labelHeader: ({ t }) => t('Disputes'),
    label: ({ t }) => t('Disputes'),
    component: loadable(
      () => import('../../../components/dispute/DisputeDetails'),
    ),
    exact: false,
    requiredRoles: [
      ROLES.OPEN_PIX.DISPUTE_ALL,
      ROLES.OPEN_PIX.DISPUTE_VIEW,
      ROLES.DISPUTE.VIEW,
      ROLES.DISPUTE.ALL,
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'dispute.detail.data',
        path: `${APP_PATH}/dispute/details/:id/data`,
        labelHeader: ({ t }) => t('Details'),
        label: ({ t }) => t('Details'),
        component: loadable(
          () => import('../../../components/dispute/NewDisputeData'),
        ),
        exact: true,
        requiredRoles: [
          ROLES.OPEN_PIX.DISPUTE_ALL,
          ROLES.OPEN_PIX.DISPUTE_VIEW,
          ROLES.DISPUTE.VIEW,
          ROLES.DISPUTE.ALL,
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
];
