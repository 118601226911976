import React, { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

import { useControlledState } from '@woovi/hooks';

type SwipeableDialogContext = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeOnBackdropClick?: boolean;
};

type SwipeableDialogProviderProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  onOpenChange?: () => void;
  closeOnBackdropClick?: boolean;
};

const SwipeableDialogContext = createContext<SwipeableDialogContext | null>(
  null,
);

export const useSwipeableDialogContext = () => {
  const context = useContext(SwipeableDialogContext);

  if (!context) {
    throw new Error('Only should be used on the SwipeableDialog scope');
  }

  return context;
};

export const SwipeableDialog = (
  props: SwipeableDialogProviderProps,
): ReactNode => {
  const {
    children,
    isOpen = false,
    onOpenChange,
    closeOnBackdropClick = true,
  } = props;

  const [open, setOpen] = useControlledState(isOpen, onOpenChange);

  const value: SwipeableDialogContext = {
    open,
    setOpen,
    closeOnBackdropClick,
  };

  return (
    <SwipeableDialogContext.Provider value={value}>
      {children}
    </SwipeableDialogContext.Provider>
  );
};

SwipeableDialog.displayName = 'SwipeableDialog';
