import { useEffect } from 'react';
import { graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';

import {
  concatPermissions,
  getPermissionsFromRoutes,
  hasFeature,
  hasRole,
} from '../../security';
import { routeToMain } from '../utils';
import type { useAuthenticated_user } from './__generated__/useAuthenticated_user.graphql';

graphql`
  fragment useAuthenticated_user on User {
    company {
      features
    }
    isAdmin
    allRoles
    ...permissionsUserFragment @relay(mask: false)
  }
`;

export const useAuthenticated = (user: useAuthenticated_user) => {
  const history = useHistory();

  const isAuthenticated = !!user;

  const redirectIfNotAllowed = () => {
    const { pathname } = location;

    if (!user) {
      return history.push(routeToMain('forbidden'));
    }

    const { requiredRoles, requiredFeatures } = concatPermissions(
      getPermissionsFromRoutes([pathname]),
    );

    if (!hasFeature(user, requiredFeatures)) {
      return history.push(routeToMain('notFound'));
    }

    if (
      !location.pathname.startsWith('/home/forbidden') &&
      !hasRole(user, requiredRoles)
    ) {
      return history.push(routeToMain('forbidden'));
    }
  };

  const redirectIfNotAuthenticated = () => {
    // if (!company) {
    //   // handled by useDomainName
    //   return;
    // }

    if (
      !user &&
      !location.pathname.startsWith('/login') &&
      !location.pathname.startsWith('/signUp')
    ) {
      window.location.href = '/login';

      return;
    }

    if (user) {
      redirectIfNotAllowed();
    }
  };

  useEffect(() => {
    redirectIfNotAuthenticated();
  });

  return isAuthenticated;
};
