import type { ReactElement, CSSProperties, ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

import { MODULES } from '@woovi/roles';

import type { FeatureFlag_user$key } from './__generated__/FeatureFlag_user.graphql';
import { usePermission } from '../../../../security/usePermission';

const DefaultFallback = (): null => null;

export type FeatureFlagProps = {
  features?: string[];
  roles?: string[];
  projectsTypes?: string[];
  children: ReactElement | ReactElement[];
  fallbackComponent?: ReactElement;
  user: FeatureFlag_user$key;
  isTemp?: boolean;
  tempBorder?: boolean;
  customHasPermission?: boolean;
  style?: CSSProperties;
};

export const FeatureFlag = ({
  children,
  fallbackComponent,
  features = [],
  roles = [],
  projectsTypes = [],
  isTemp = false,
  tempBorder = true,
  customHasPermission = true,
  ...props
}: FeatureFlagProps): ReactNode => {
  const user = useFragment(
    graphql`
      fragment FeatureFlag_user on User {
        ...usePermission_user
      }
    `,
    props.user,
  );

  const { hasPermission } = usePermission(user);
  const userHasPermission = hasPermission(features, roles, projectsTypes);

  if (!userHasPermission || !customHasPermission) {
    if (fallbackComponent) {
      return fallbackComponent;
    }

    return <DefaultFallback />;
  }

  // we can simplify
  const hasTemp = isTemp || features.includes(MODULES.TEMP);

  if (hasTemp) {
    if (tempBorder) {
      return (
        <div style={{ border: '1px dashed red', ...props.style }}>
          {children}
        </div>
      );
    }
  }

  return children;
};

export default FeatureFlag;
