import qs from 'query-string';

const stringify = (obj: Record<string, unknown>) =>
  qs.stringify(obj, { arrayFormat: 'index' });
const parse = (query: string) => qs.parse(query, { arrayFormat: 'index' });

export const queryString = {
  stringify,
  parse,
};
