import type { ReactNode } from 'react';
type Props = {
  width?: string;
  height?: string;
  color?: string;
};

const WooviLogo = ({
  width,
  height = '40px',
  color = '#03D69D',
}: Props): ReactNode => {
  return (
    <svg
      id='WooviLogo'
      data-testid='WooviLogo'
      width={width}
      height={height}
      viewBox='0 0 124 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group'>
        <path
          id='Vector'
          d='M101.243 35.5452C99.1586 35.5452 97.2858 34.3098 96.5084 32.3684L89.4766 15.5312C88.5932 13.4133 90.148 11.1189 92.4095 11.1189C93.7169 11.1189 94.883 11.8955 95.3777 13.1309L101.243 27.709L107.109 13.1309C107.604 11.9308 108.77 11.1189 110.077 11.1189C112.374 11.1189 113.894 13.4486 113.01 15.5312L105.978 32.4037C105.201 34.3098 103.328 35.5452 101.243 35.5452Z'
          fill={color}
        />
        <path
          id='Vector_2'
          d='M122.833 1.16484C123.575 1.9414 123.964 2.82386 123.964 3.8828C123.964 4.94175 123.575 5.8595 122.833 6.56546C122.091 7.27143 121.137 7.62441 120.042 7.62441C118.911 7.62441 117.957 7.27143 117.215 6.56546C116.473 5.8595 116.12 4.94175 116.12 3.8828C116.12 2.82386 116.508 1.9414 117.25 1.16484C117.992 0.38828 118.946 0 120.042 0C121.137 0 122.091 0.38828 122.833 1.16484ZM116.791 32.3331V14.3664C116.791 12.6015 118.24 11.1542 120.007 11.1542C121.773 11.1542 123.222 12.6015 123.222 14.3664V32.2978C123.222 34.0628 121.773 35.51 120.007 35.51C118.24 35.5453 116.791 34.0981 116.791 32.3331Z'
          fill={color}
        />
        <g id='Group_2'>
          <path
            id='Vector_3'
            d='M23.8226 35.5453C21.8792 35.5453 20.2184 34.2746 19.7237 32.4391L16.5081 20.5083L13.2572 32.4391C12.7625 34.2746 11.1017 35.5453 9.1936 35.5453C7.32081 35.5453 5.66002 34.3099 5.12998 32.4744L0.112297 14.9665C-0.41774 13.0957 0.995693 11.2249 2.93916 11.2249C4.24659 11.2249 5.41267 12.1073 5.76603 13.378L9.40562 26.5443L12.8332 13.9781C13.2926 12.3544 14.7413 11.2249 16.4374 11.2249C18.1336 11.2249 19.6177 12.3544 20.0417 13.9781L23.4693 26.5443L27.1089 13.378C27.4622 12.1073 28.6283 11.2249 29.9357 11.2249H30.0417C31.9852 11.2249 33.3986 13.0957 32.8686 14.9665L27.8863 32.5097C27.3916 34.3099 25.7308 35.5453 23.8226 35.5453Z'
            fill={color}
          />
        </g>
        <path
          id='Vector_4'
          d='M53.7168 25.6265C55.4482 27.3561 57.0383 28.9446 58.6285 30.533C55.0242 34.9453 50.5366 37.6632 44.5648 36.5337C39.0171 35.4747 35.3775 32.0508 33.9287 26.5443C32.4093 20.7201 35.0948 14.6841 40.2892 11.719C45.4835 8.754 51.9853 9.53056 56.261 13.731C61.0313 18.3904 65.6956 23.1557 70.4306 27.8503C72.8688 30.2506 76.4731 30.533 79.0879 28.5916C82.0208 26.4031 82.5508 22.1673 80.2893 19.3082C77.9925 16.449 73.7522 15.9901 70.996 18.3904C70.0419 19.2023 69.2645 20.2259 68.4518 21.1437C66.685 19.3788 65.0949 17.7903 63.5048 16.2019C67.0031 11.8955 71.42 8.96579 77.9925 10.2718C83.4695 11.3308 87.5332 15.8136 88.4519 21.426C89.3353 26.9326 86.4378 32.6156 81.3847 35.2629C76.2257 37.9456 69.9713 37.0278 65.7663 32.898C61.0666 28.2386 56.4023 23.5439 51.7026 18.8846C49.6885 16.8726 46.8616 16.2725 44.3881 17.4021C41.8793 18.5316 40.5012 20.5083 40.4658 23.2969C40.4305 26.0501 41.7026 28.1327 44.2114 29.2975C46.7556 30.4624 49.1585 30.1094 51.2786 28.2739C52.1973 27.4267 52.9394 26.4737 53.7168 25.6265Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default WooviLogo;
