import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/pix-machine';

export const pixMachineRoutes: RouteType[] = [
  {
    name: 'pix-machine.detail',
    path: `${PATH}/:id`,
    labelHeader: ({ t }) => t('Pix Machine'),
    label: ({ t }) => t('Pix Machine'),
    component: loadable(
      () =>
        import(
          '../../components/pixMachine/detail/PixMachineDetailScreenHeader'
        ),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.QRCODES,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'pix-machine.data',
        path: `${PATH}/:id/detail`,
        labelHeader: ({ t }) => t('Detail'),
        label: ({ t }) => t('Detail'),
        component: loadable(
          () => import('../../components/pixMachine/detail/PixMachineDetail'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
  {
    name: 'pix-machines.create',
    path: `${PATH}s/create`,
    label: ({ t }) => t('Pix Machine'),
    component: loadable(
      () => import('../../components/pixMachine/create/PixMachineCreate'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.QRCODES,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'pix-machines.create-mac-address',
    path: `${PATH}s/create/mac/:macaddress`,
    label: ({ t }) => t('Pix Machine'),
    component: loadable(
      () =>
        import('../../components/pixMachine/create/PixMachineCreateMacAddress'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.QRCODES,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
];
