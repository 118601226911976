import { config } from '@woovi/shared';

export const getChatWootLinkToConsole = (companyId: string): string => {
  if (config?.APP_ENV === 'production') {
    return `https://console.woovi.io/console/companies/details/${companyId}/detail`;
  }

  if (config?.APP_ENV === 'staging') {
    return `https://console.woovi.dev/console/companies/details/${companyId}/detail`;
  }

  return `http://localhost:8102/console/companies/details/${companyId}/detail`;
};
