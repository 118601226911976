export const defaultThousandSeparator = '.';
export const defaultDecimalSeparator = ',';

const replaceAll = (str, find, replace) => {
  // eslint-disable-next-line
  var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');

  return str.replace(new RegExp(escapedFind, 'g'), replace);
};

export const parseNumber = (
  num: string | number | null,
  options = {},
): number | null => {
  if (num === undefined || num === null || typeof num === 'boolean') {
    return null;
  }

  // do not parse numbers
  if (typeof num !== 'string') {
    return num;
  }

  const {
    decimalSeparator = defaultDecimalSeparator,
    thousandSeparator = defaultThousandSeparator,
  } = options;

  const noThousands = replaceAll(num.toString(), thousandSeparator, '');
  const rightDecimals = noThousands.replace(decimalSeparator, '.');

  return parseFloat(rightDecimals);
};
