import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import IconButton from '@mui/material/IconButton';
import { graphql, useFragment } from 'react-relay';

import { useThemeMode } from '@woovi/ui';

import type { HeaderButtonTheme_user$key } from './__generated__/HeaderButtonTheme_user.graphql';
import FeatureTemp from '../../components/common/v2/featureFlag/FeatureTemp';

import type { ReactNode } from 'react';

type HeaderButtonThemeProps = {
  user: HeaderButtonTheme_user$key;
};

const HeaderButtonTheme = (props: HeaderButtonThemeProps): ReactNode => {
  const { toggleTheme, themeMode } = useThemeMode();

  const user = useFragment<HeaderButtonTheme_user$key>(
    graphql`
      fragment HeaderButtonTheme_user on User {
        ...FeatureTemp_user
      }
    `,
    props.user,
  );

  return (
    <FeatureTemp user={user}>
      <IconButton onClick={toggleTheme} sx={{ color: 'common.white' }}>
        {themeMode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
      </IconButton>
    </FeatureTemp>
  );
};

export default HeaderButtonTheme;
