import { t } from 'i18next';

export const validateStrongPasswordValue = (password: string) => {
  const minLength = 8;

  const hasMinLength = password.length >= minLength;
  const hasUpperCase = /[A-Z]/;
  const hasLowerCase = /[a-z]/;
  const hasNumber = /\d/;
  const hasSpecialChar = /[^A-Za-z0-9]/;

  if (!hasMinLength) {
    return {
      error: true,
      message: t('Password must contain at least 8 characters'),
    };
  }

  if (!hasUpperCase.test(password)) {
    return {
      error: true,
      message: t('Password must contain at least one uppercase letter'),
    };
  }

  if (!hasLowerCase.test(password)) {
    return {
      error: true,
      message: t('Password must contain at least one lowercase letter'),
    };
  }

  if (!hasNumber.test(password)) {
    return {
      error: true,
      message: t('Password must contain at least one number'),
    };
  }

  if (!hasSpecialChar.test(password)) {
    return {
      error: true,
      message: t('Password must contain at least one special character'),
    };
  }

  return {
    error: false,
    message: null,
  };
};
