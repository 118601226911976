import Link from '@mui/material/Link';
import { GridRow } from '@mui/x-data-grid';
import { useGridRootProps } from '@mui/x-data-grid-pro';

import type { ReactNode } from 'react';

export type DataGridRowProps = {
  href: (row: Record<string, unknown>) => string;
};

/**
 * A custom implementation of the GridRow component focusing in anchored rows.
 *
 * See the default implementation [here](https://github.com/mui/mui-x/blob/master/packages/grid/x-data-grid/src/components/GridRow.tsx).
 */
const DataGridRow = (props: DataGridRowProps): ReactNode => {
  const root = useGridRootProps();

  if (!root.experimentalFeatures?.rowAnchor) {
    return <GridRow {...props} />;
  }

  const href = props.href(props.row);

  return (
    <Link
      className='MuiDataGrid-rowLink'
      href={href}
      sx={{
        cursor: props.href ? 'pointer' : 'default',
        textDecoration: 'none',
        color: 'black',
      }}
    >
      <GridRow {...props} />
    </Link>
  );
};

export default DataGridRow;
