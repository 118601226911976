import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import _IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactNode } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useSidebar } from '@woovi/ui';

import { safeGoBack } from '../../router/utils';

const IconButton = styled(_IconButton)`
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

const PATHS = ['/home', '/home/', '/home/openpix', '/home/start', '/home/hr'];

export const MenuButton = (): ReactNode => {
  const location = useLocation();
  const { sidebarToggle } = useSidebar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const history = useHistory();

  const onMenuClick = () => {
    safeGoBack(history, 'home');
  };

  if (isMobile || isIpad) {
    return (
      <IconButton
        onClick={sidebarToggle}
        size='large'
        data-testid='menu-button'
      >
        <MenuIcon fontSize='large' />
      </IconButton>
    );
  }

  if (PATHS.includes(location.pathname)) {
    return (
      <IconButton
        onClick={sidebarToggle}
        size='large'
        data-testid='menu-button'
      >
        <MenuIcon fontSize='large' />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={onMenuClick} size='large'>
      <ArrowBackOutlinedIcon fontSize='large' />
    </IconButton>
  );
};
