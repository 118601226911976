import type { GridColTypeDef } from '@mui/x-data-grid-pro';

import { convertToCurrency } from '@woovi/utils';

export const cellTypeCurrency: GridColTypeDef = {
  extendType: 'number',
  valueFormatter: ({ value }) => {
    if (value === null) {
      return '-';
    }

    if (typeof value !== 'number') {
      return value;
    }

    return convertToCurrency(value / 100);
  },
};

export const GRID_CURRENCY_CUSTOM_COL_DEF: GridColTypeDef = {
  type: 'number',
  valueFormatter: ({ value }) => {
    if (value === null) {
      return '-';
    }

    if (typeof value !== 'number') {
      return value;
    }

    return convertToCurrency(value / 100);
  },
};
