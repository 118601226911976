import DialogActions from '@mui/material/DialogActions';
import React from 'react';

import type { ReactNode } from 'react';

type SwipeableDialogActionsProps = {
  children: React.ReactNode;
};

const DIALOG_ACTION_NAME = 'SwipeableDialogActions';

export const SwipeableDialogActions = (
  props: SwipeableDialogActionsProps,
): ReactNode => {
  const { children } = props;

  return <DialogActions>{children}</DialogActions>;
};

SwipeableDialogActions.displayName = DIALOG_ACTION_NAME;
