import { ROOT_TYPE } from 'relay-runtime';
import type { MissingFieldHandler } from 'relay-runtime/lib/store/RelayStoreTypes';

export const missingScalarDebug = {
  kind: 'scalar',
  handle(field, record, args, store) {
    // eslint-disable-next-line
    console.log('missing scalar', {
      field,
      record,
      args,
      store,
    });

    return undefined;
  },
};

export const missingLinkedDebug = {
  kind: 'linked',
  handle(field, record, args, store) {
    if (
      record != null &&
      record.__typename === ROOT_TYPE &&
      field.name === 'node' &&
      args.id
    ) {
      // eslint-disable-next-line
      console.log('missing node', {
        field,
        record,
        args,
        store,
      });

      return args.id;
    }

    // eslint-disable-next-line
    console.log('missing linked', {
      field,
      record,
      args,
      store,
    });

    return undefined;
  },
};

export const missingPluralDebug = {
  kind: 'pluralLinked',
  handle(field, record, args, store) {
    return undefined;
  },
};

export const missingLinked = {
  kind: 'linked',
  handle(field, record, args, store) {
    if (
      record != null &&
      record.__typename === ROOT_TYPE &&
      field.name === 'node' &&
      args.id
    ) {
      // eslint-disable-next-line
      console.log('missing node', {
        field,
        record,
        args,
        store,
      });

      return args.id;
    }

    return undefined;
  },
};

export const missingFieldHandlersDebug: ReadonlyArray<MissingFieldHandler> = [
  missingScalarDebug,
  missingLinkedDebug,
  missingPluralDebug,
];

export const missingFieldHandlers: ReadonlyArray<MissingFieldHandler> = [
  missingLinked,
];

const IS_SERVER = typeof window === typeof undefined;
const isDev = process.env.NODE_ENV === 'development';
const CLIENT_DEBUG = false;
const SERVER_DEBUG = false;

const showLog =
  isDev && ((IS_SERVER && SERVER_DEBUG) || (!IS_SERVER && CLIENT_DEBUG));

export const getMissingFieldHandlers = () => {
  if (showLog) {
    return {
      missingFieldHandlers: missingFieldHandlersDebug,
    };
  }

  return {
    missingFieldHandlers,
  };
};
