/**
 * @generated SignedSource<<1f5defc3ced8112da6be1ed089ee5127>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenu_query$data = {
  readonly me: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "NotificationMenu_query";
};
export type NotificationMenu_query$key = {
  readonly " $data"?: NotificationMenu_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationMenu_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationMenu_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "359add4f3ea1b181cc3365ef2f14141b";

export default node;
