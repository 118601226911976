import {
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
} from '@mui/x-data-grid-pro';
import type {
  GridToolbarContainerProps,
  GridCsvExportOptions,
} from '@mui/x-data-grid-pro';

import type { ReactNode } from 'react';

const csvOptions: GridCsvExportOptions = { delimiter: ';' };

export const DataGridToolbarExport = (
  props: GridToolbarContainerProps,
): ReactNode => {
  return (
    <GridToolbarContainer {...props}>
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem options={csvOptions} />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
};
