import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';

import { accountRegisterRoutes } from './accountRegisterRoutes';
import { APP_PATH } from './appPath';
import { cardRoutes } from './cardRoutes';
import { chargeRoutes } from './chargeRoutes';
import { checkoutRoutes } from './checkoutRoutes';
import { disputeRoutes } from './disputeRoutes';
import { paymentRoutes } from './paymentRoutes';
import { pixQrCodeRoutes } from './pixQrCodeRoutes';
import { subscriptionRoutes } from './subscriptionRoutes';
import { transactionRoutes } from './transactionRoutes';
import { webhookRoutes } from './webhookRoutes';
import type { RouteType } from '../../utils/index';

export const openpixRoutes: RouteType[] = [
  {
    name: 'home',
    path: `${APP_PATH}/start`,
    component: loadable(
      () => import('../../../components/home/openpix/HomeOpenPix'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Home'),
    requiredRoles: [],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'companyBank.details',
    path: `${APP_PATH}/companyBank/details/:id`,
    labelHeader: ({ t }) => t('Company Bank Account'),
    label: ({ t }) => t('Company Bank Account'),
    component: loadable(
      () =>
        import(
          '../../../components/companyBankAccount/details/CompanyBankAccountDetails'
        ),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.ACCOUNT_WITHDRAW,
      ROLES.OPEN_PIX.ACCOUNT_STATEMENT,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'companyBank.details.data',
        path: `${APP_PATH}/companyBank/details/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () =>
            import(
              '../../../components/companyBankAccount/details/CompanyBankAccountDataNew'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.ACCOUNT_WITHDRAW,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'companyBank.details.data-old',
        path: `${APP_PATH}/companyBank/details/:id/dataold`,
        labelHeader: ({ t }) => t('Data (old)'),
        label: ({ t }) => t('Data (old)'),
        component: loadable(
          () =>
            import(
              '../../../components/companyBankAccount/details/CompanyBankAccountData'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.ACCOUNT_WITHDRAW,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
      {
        name: 'companyBank.details.charges',
        path: `${APP_PATH}/companyBank/details/:id/charges`,
        labelHeader: ({ t }) => t('Charges'),
        label: ({ t }) => t('Charges'),
        component: loadable(
          () =>
            import(
              '../../../components/companyBankAccount/details/CompanyBankAccountCharges'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'companyBank.details.statement',
        path: `${APP_PATH}/companyBank/details/:id/statement`,
        labelHeader: ({ t }) => t('Statement'),
        label: ({ t }) => t('Statement'),
        component: loadable(
          () =>
            import(
              '../../../components/companyBankAccount/details/statement/AccountStatement'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.REFUND,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.ACCOUNT_STATEMENT,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'companyBank.details.ledger',
        path: `${APP_PATH}/companyBank/details/:id/ledger`,
        labelHeader: ({ t }) => t('Ledger Statement'),
        label: ({ t }) => t('Ledger Statement'),
        component: loadable(
          () =>
            import(
              '../../../components/companyBankAccount/details/statement/ledger/AccountLedgerStatement'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.REFUND,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.ACCOUNT_STATEMENT,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
      {
        name: 'companyBank.details.transactions',
        path: `${APP_PATH}/companyBank/details/:id/transactions`,
        labelHeader: ({ t }) => t('Transactions'),
        label: ({ t }) => t('Transactions'),
        component: loadable(
          () =>
            import(
              '../../../components/companyBankAccount/details/CompanyBankAccountTransactions'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'companyBank.details.settings',
        path: `${APP_PATH}/companyBank/details/:id/settings`,
        labelHeader: ({ t }) => t('Settings'),
        label: ({ t }) => t('Settings'),
        component: loadable(
          () =>
            import(
              '../../../components/companyBankAccount/details/settings/CompanyBankAccountDetailSettings'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.REFUND,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'companyBank.details.edit',
        path: `${APP_PATH}/companyBank/details/:id/edit`,
        labelHeader: ({ t }) => t('Registered Data'),
        label: ({ t }) => t('Registered Data'),
        component: loadable(
          () =>
            import(
              '../../../components/companyBankAccount/details/edit/CompanyBankAccountDetailEditData'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
  {
    name: 'companybankaccount.create',
    path: `${APP_PATH}/company/bank/account/create`,
    component: loadable(
      () =>
        import(
          '../../../components/companyBankAccount/create/CompanyBankAccountCreate'
        ),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  ...chargeRoutes,
  ...subscriptionRoutes,
  ...paymentRoutes,
  ...accountRegisterRoutes,
  ...webhookRoutes,
  ...pixQrCodeRoutes,
  ...transactionRoutes,
  ...disputeRoutes,
  ...checkoutRoutes,
  ...cardRoutes,
];
