import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES } from '@woovi/roles';

import { APP_PATH } from './appPath';
import type { RouteType } from '../../utils';

const PATH = '/home/checkouts';

export const checkoutRoutes: RouteType[] = [
  {
    path: `${PATH}`,
    name: 'checkout',
    component: loadable(
      () => import('../../../components/checkout/CheckoutTabs'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Checkout'),
    routes: [
      {
        component: loadable(
          () => import('../../../components/checkout/CheckoutList'),
        ),
        path: `${PATH}/list`,
        name: 'checkout.list',
        exact: true,
        label: ({ t }) => t('Checkouts'),
        labelHeader: ({ t }) => t('Checkouts'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.CHECKOUT],
      },
      {
        component: loadable(
          () =>
            import('../../../components/checkout/tutorial/CheckoutTutorial'),
        ),
        path: `${PATH}/tutorial`,
        name: 'checkout.tutorial',
        exact: true,
        label: ({ t }) => t('Tutorial'),
        labelHeader: ({ t }) => t('Tutorial'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.CHECKOUT],
      },
    ],
    requiredFeatures: [MODULES.CHECKOUT],
  },
  {
    name: 'checkout.detail',
    path: `${APP_PATH}/checkout/:id`,
    component: loadable(
      () => import('../../../components/checkout/detail/CheckoutDetailTabs'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Checkout'),
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.CHECKOUT],
    routes: [
      {
        name: 'checkout.detail.data',
        component: loadable(
          () =>
            import('../../../components/checkout/detail/CheckoutDetailData'),
        ),
        path: `${APP_PATH}/checkout/:id/data`,
        exact: true,
        label: ({ t }) => t('Detail'),
        labelHeader: ({ t }) => t('Detail'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.CHECKOUT],
      },
      {
        name: 'checkout.detail.link',
        component: loadable(
          () => import('../../../components/checkout/link/CheckoutLink'),
        ),
        path: `${APP_PATH}/checkout/:id/link`,
        exact: true,
        label: ({ t }) => t('Link'),
        labelHeader: ({ t }) => t('Link'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.CHECKOUT, MODULES.TEMP],
      },
      {
        name: 'checkout.detail.charges',
        component: loadable(
          () =>
            import(
              '../../../components/checkout/detail/CheckoutDetailChargeList'
            ),
        ),
        path: `${APP_PATH}/checkout/:id/charges`,
        exact: true,
        label: ({ t }) => t('Charges'),
        labelHeader: ({ t }) => t('Charges'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.CHECKOUT],
      },
      {
        name: 'checkout.detail.settings',
        component: loadable(
          () =>
            import(
              '../../../components/checkout/detail/settings/CheckoutDetailSettings'
            ),
        ),
        path: `${APP_PATH}/checkout/:id/settings`,
        exact: true,
        label: ({ t }) => t('Settings'),
        labelHeader: ({ t }) => t('Settings'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.CHECKOUT],
      },
    ],
  },
  {
    name: 'checkout.create',
    path: `${APP_PATH}/checkout-create`,
    component: loadable(
      () => import('../../../components/checkout/create/CheckoutCreate'),
    ),
    exact: true,
    label: ({ t }) => t('Checkout'),
    labelHeader: ({ t }) => t('Checkout'),
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.CHECKOUT],
  },
];
