import { Notifications } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import type { Notification_query$key } from './__generated__/Notification_query.graphql';
import { NotificationMenu } from './NotificationMenu';

type NotificationProps = {
  query: Notification_query$key;
};

export const Notification = (props: NotificationProps): React.ReactNode => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const query = useFragment<Notification_query$key>(
    graphql`
      fragment Notification_query on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String", defaultValue: null }
        filters: { type: "AppEventFilter" }
      ) {
        ...NotificationMenu_query
        me {
          appEvents(first: $first, after: $after, filters: $filters)
            @connection(key: "Notification_me_appEvents", filters: []) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `,
    props.query,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const useBadge = (): React.ReactNode => {
    const notificationsCount = query?.me?.appEvents?.edges?.length || 0;
    const hasNotifications = notificationsCount > 0;

    return (
      <Badge
        badgeContent={notificationsCount}
        color='error'
        invisible={!hasNotifications}
        sx={{
          '& .MuiBadge-badge': {
            fontSize: 10,
            height: 14,
            minWidth: 14,
            padding: 0.5,
          },
        }}
      >
        <Notifications />
      </Badge>
    );
  };

  return (
    <>
      <IconButton sx={{ color: 'white' }} onClick={handleClick}>
        {useBadge()}
      </IconButton>
      <NotificationMenu
        query={query}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
};
