/**
 * @generated SignedSource<<8c115aa766b0e02d89e9d850b73426d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useChatwoot_user$data = {
  readonly _id: string;
  readonly company: {
    readonly id: string;
    readonly nameFriendly: string | null | undefined;
  } | null | undefined;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly phoneNumbers: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly userMaster: {
    readonly chatWootAuth: string | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "useChatwoot_user";
};
export type useChatwoot_user$key = {
  readonly " $data"?: useChatwoot_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useChatwoot_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useChatwoot_user"
};

(node as any).hash = "b60def23e5a1ff1eb93cec3ca02fa518";

export default node;
