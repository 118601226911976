import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';
import { formatToCPFOrCNPJ } from 'brazilian-values';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'react-relay';

import type { UserMenuCompanyInfo_user$key } from './__generated__/UserMenuCompanyInfo_user.graphql';

import type { ReactNode } from 'react';

type UserMenuCompanyInfoProps = {
  user: UserMenuCompanyInfo_user$key;
};

export const UserMenuCompanyInfo = (
  props: UserMenuCompanyInfoProps,
): ReactNode => {
  const { t } = useTranslation();

  const user = useFragment<UserMenuCompanyInfo_user$key>(
    graphql`
      fragment UserMenuCompanyInfo_user on User {
        company {
          name
          taxID {
            taxID
          }
          partner {
            name
            nameFriendly
          }
        }
      }
    `,
    props.user,
  );

  const companyTaxID = user.company?.taxID?.taxID;

  return (
    <Stack>
      <Typography fontWeight={600}>{user.company?.name}</Typography>
      {companyTaxID && (
        <Typography color='grey.600'>
          CNPJ: {formatToCPFOrCNPJ(companyTaxID)}
        </Typography>
      )}
      {user.company?.partner?.name && (
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <InfoOutlinedIcon color='primary' fontSize='small' />{' '}
          <Typography>
            {t('Partner company')}:{' '}
            {user.company?.partner?.nameFriendly || user.company?.partner?.name}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
