import { isCPFOrCNPJ } from 'brazilian-values';
import type { FormikHelpers } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import parsePhoneNumberFromString, {
  parsePhoneNumber,
} from 'libphonenumber-js';
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import * as yup from 'yup';
import type { ObjectShape, OptionalObjectSchema } from 'yup/lib/object';

import { useControlledState } from '@woovi/hooks';
import { useMutationCallbacks } from '@woovi/relay';
import { ActionButton } from '@woovi/ui';
import {
  schemaObjectHasRequiredField,
  registerYupValidations,
} from '@woovi/utils';

import type { CustomerCreateFormModal_query$key } from './__generated__/CustomerCreateFormModal_query.graphql';
import type { CustomerCreateMutation } from '../__generated__/CustomerCreateMutation.graphql';
import { CustomerCreate } from '../CustomerCreateMutation';
import CustomerFormModalFields from '../modal/CustomerFormModalFields';

registerYupValidations();

export type CustomerCreateFormModalValues = {
  name: string;
  email: string;
  phone: string;
  taxID: string;
  address: {
    zipcode: string;
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    complement: string;
  };
};

export type CustomerCreateFormModalProps<T extends ObjectShape> = {
  query: CustomerCreateFormModal_query$key;
  handleModal: (isOpen: boolean) => void;
  isOpen: boolean;
  onSubmit?: (
    values: CustomerCreateFormModalValues,
    helpers: FormikHelpers<CustomerCreateFormModalValues>,
  ) => unknown;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryAction?: () => unknown;
  afterCompleted?: (args?: unknown) => unknown;
  header?: string;
  validationSchema?: OptionalObjectSchema<T>;
  handleClose?: () => void;
};

const CustomerCreateFormModal = <T extends ObjectShape>(
  props: CustomerCreateFormModalProps<T>,
): ReactNode => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useControlledState(
    props?.isOpen,
    props.handleModal,
  );

  const {
    header = t('Create customer'),
    primaryButtonLabel = t('Save'),
    secondaryButtonLabel = t('Cancel'),
    secondaryAction = () => setIsOpen(!isOpen),
    handleClose = () => setIsOpen(false),
  } = props;

  const query = useFragment<CustomerCreateFormModal_query$key>(
    graphql`
      fragment CustomerCreateFormModal_query on Query {
        me {
          id
        }
      }
    `,
    props.query,
  );

  const [customerCreate, isPending] =
    useMutationCallbacks<CustomerCreateMutation>({
      name: 'CustomerCreate',
      mutation: CustomerCreate,
      success: t('Customer created'),
      error: t('Error creating new Customer'),
      afterCompleted: ({ CustomerCreate }) => {
        resetForm();
        setIsOpen(false);
        props.afterCompleted && props?.afterCompleted(CustomerCreate);
      },
    });

  const onSubmit = (
    values: CustomerCreateFormModalValues,
    helpers: FormikHelpers<CustomerCreateFormModalValues>,
  ) => {
    if (props.onSubmit) {
      return props.onSubmit(values, helpers);
    }

    const { name, email, phone, taxID, address } = values;

    const getPhone = () => {
      const phoneWithoutPrefix =
        parsePhoneNumberFromString(phone)?.formatNational();

      if (!phoneWithoutPrefix) {
        return {};
      }

      return { phone: parsePhoneNumber(phone, 'BR').number };
    };

    const input = {
      name,
      email,
      ...getPhone(),
      taxID,
      address,
    };

    const connections = [
      'OpenPixCustomerList_customers',
      'CustomerDrawer_filterCustomers',
    ].map((connection) => {
      return ConnectionHandler.getConnectionID(query.me?.id, connection);
    });

    const config = {
      variables: {
        input,
        connections,
      },
    };

    customerCreate(config);
  };

  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      phone: '',
      taxID: '',
      address: {
        zipcode: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: '',
      },
    }),
    [],
  );

  const validationSchema =
    props.validationSchema ??
    yup.object().shape({
      name: yup.string().required(t('Name is required')),
      email: yup.string().email(t('Email must be a valid email')).notRequired(),
      phone: yup.string().isValidPhoneNumber(t('Phone is invalid')),
      taxID: yup
        .string()
        .test('isCPF', t('Customer TaxID is invalid'), (value) => {
          if (!value) {
            return true;
          }

          return isCPFOrCNPJ(value);
        })
        .required(t('TaxID is required')),
      address: yup.object().shape({
        zipcode: yup.string(),
        street: yup.string(),
        number: yup.string(),
        neighborhood: yup.string(),
        city: yup.string(),
        state: yup.string(),
        complement: yup.string(),
      }),
    });

  const formikbag = useFormik<CustomerCreateFormModalValues>({
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, isValid, resetForm } = formikbag;

  const isSubmitDisabled = !isValid || isPending;

  const renderActions = (): ReactNode => {
    return (
      <>
        <ActionButton
          variant='text'
          color='secondary'
          data-testid={'btnCancelCustomerDrawer'}
          onClick={secondaryAction}
        >
          {secondaryButtonLabel}
        </ActionButton>
        <ActionButton
          color='primary'
          type='submit'
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          data-testid={'btnSaveCustomerDrawer'}
        >
          {primaryButtonLabel}
        </ActionButton>
      </>
    );
  };

  const addressIsRequired = schemaObjectHasRequiredField(
    validationSchema.fields.address,
  );

  return (
    <FormikProvider value={formikbag}>
      <CustomerFormModalFields
        title={header}
        handleClose={handleClose}
        isOpen={isOpen}
        renderActions={renderActions}
        addressIsRequired={addressIsRequired}
      />
    </FormikProvider>
  );
};

export default CustomerCreateFormModal;
